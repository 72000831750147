import AdinaMorris from "../images/AdinaMorris.jpg"
import ArunSunder from "../images/ArunSunder.jpg"
import BeateHain from "../images/BeateHain.jpg"
import ForumShah from "../images/ForumShah.jpg"
import MaryannEddah from "../images/MaryannEddah.jpg"


export const customers = [
    {
        id: 1,
        name: 'Beate Hain',
        img: BeateHain,
        age: '38',
        review:'',
        content: "I'm here at my layover and got a full body check-up with Vital MRI (Aarthi Scans) and I'm very, very satisfied because in my country it's difficult to get any appointments. Sometimes you have to wait for weeks or even months to get CT or MRI. So I'm very well provided here. Thank you very much.",
      },
      {
        id: 2,
        name: 'Maryann Eddah',
        img: MaryannEddah,
        age: '59',
        review:'https://www.instagram.com/p/C-r5zIBvRWP/',
        content: "The fact that the technology exists, that we can profit from knowing if there's a weakness in the body or if there's something that we can improve, it's a fantastic opportunity.",
      },
      {
        id: 3,
        name: 'Adina Morris',
        img: AdinaMorris,
        age: '52',
        review:'',
        content: "I came in for a Whole body MRI and I was treated with so much care and I felt very comfortable and it was a very easy process for me and I can't believe 90 minutes went by so fast because it was very seamless to me. I highly recommend it and I'm looking forward to my results.",
      },
      {
        id: 4,
        name: 'Forum Shah',
        img: ForumShah,
        age: '38',
        review:'',
        content: "We really didn't feel like we were coming here for the first time to get the test done and it was a very very good experience. I'll definitely refer my family and friends to get the all the tests done here and especially this package is  so comprehensive that it covers all the biological metrics that we were looking for.",
      },
      {
        id: 5,
        name: 'Arun Sunder',
        img: ArunSunder,
        age: '35',
        review:'https://www.instagram.com/reel/C_5Zhv0vrfw/',
        content: "I came to Tambaram branch for the full body MRI Scan. They treated me well and they helped me a lot. Everything went well. The tests all went well.",
      },
      
];

export default customers;