// import AOS from "aos";
// import "aos/dist/aos.css";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import customers from "../../customFunctions/CustomerData";
import "./Testimonial.css";

const Testimonial = () => {
  const settings = {
    speed : 30,
    pauseOnHover : true,
    }
  return (
    <section className="testimonial-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Our Happy Customers</h1>
              <h5> Check what they say about us...</h5>
            </div>
          </Col>
        </Row>

        <Marquee className ="review-slider" {...settings}>
        <div className="cards">
          {customers.map((item, index) => (
            <Col key={index}>
              <div className="card card-testimonial">
                <img src={ item.img } alt={item.name} />
                <p>{item.content} <br/><br/>
                {item.review && <a className="view-insta" href={item.review} target="_blank"> <InsertLinkIcon style={{ transform: "rotate(-30deg)"}}/> View in Instagram </a>}
                </p>
                <h2>{item.name}</h2>
                <h4>Age {item.age}</h4>
                <i className="fa fa-quote-left"></i>
              </div>
            </Col>
          ))}
        </div>
        </Marquee>
      </Container>
    </section>
  );
};

export default Testimonial;
