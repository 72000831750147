import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import human from "../../images/human2.png";
import "./Bodyparts.css";

const Bodyparts = () => {
  const history = useHistory();

  const onClickBook = () => {
    history.push("/Pricing");
  };
  return (
    <section className="expert-human">
      <Container>
        <Row className="align-items-center">
          <Col lg={4} className="align-margin sticky-img">
            <img
              src={human}
              alt="human body illustration"
              className="img-fluid"
            />
          </Col>
          <Col lg={7} className="parttion-two">
            <div className="typescontest-txt mt-lg-0">
              <div>
                <h3>Body parts scanned</h3>

                <ul>
                  <li className="bottom">
                    <span><span className="fw-bold">Brain</span> (including MRA of blood vessels)</span>
                  </li>
                  {/* <div style={{flex: 1, height: '1px', backgroundColor: 'black'}} /> */}
                  <li className="bottom">
                    <span> <span className="fw-bold"> Entire spine</span></span>
                  </li>
                  <li className="bottom">
                    <span> <span className="fw-bold"> Paranasal Sinuses, Neck</span> (including angiogram of carotid arteries)</span>
                  </li>
                  <li className="bottom">
                    <span className="fw-bold">Chest</span> <span> (not including heart)</span>
                  </li>
                  <li className="bottom">
                    <span className="fw-bold">Abdomen</span> <span> (liver, gallbladder, kidneys, adrenals, spleen, pancreas)</span>
                  </li>
                  <li className="bottom">
                    <span className="fw-bold">Pelvis</span> <span>(Female: uterus, ovaries and urinary bladder, Male: prostate and urinary bladder)</span>
                  </li>
                  <li className="bottom">
                    <span className="fw-bold">Bones</span> <span>(large bones of face, vertebra, hip bones, long bones of upper and lower limbs)</span>
                  </li>
                  <li >
                    <span className="fw-bold">Joints</span> <span>(shoulders, elbows, hips, knee, ankle for effusion and degeneration)</span>
                  </li>
                </ul>
              </div>
              <div>
                <h3>For detection of</h3>

                <ul>
                  <li className="bottom">
                    <span className="fw-bold">Tumours</span> <span>(abnormal growths / cysts)</span>
                  </li>
                  <li className="bottom">
                    <span>Aneurysms</span>
                  </li>
                  <li className="bottom">
                    <span>Infections & Inflammations</span>
                  </li>
                  <li className="bottom">
                    <span>Narrowing of arteries in neck & brain</span>
                  </li>
                  <li >
                    <span>Congenital abnormalities</span>
                  </li>
                </ul>
              </div>
              <div className="banner-btn m-sm-auto d-flex justify-content-around">
                <button
                  className="rad-btn bg-blue align-margin"
                  onClick={() => onClickBook()}
                >
                  Book an Appointment
                </button>
                <Link className="text-decort" to="/Knowyourrisk"><button className="rad-btn bg-black align-margin res-margin">
                  Take a Risk Test
                </button></Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Bodyparts;
